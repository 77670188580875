var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "views_shopping_mail_pages_home bbox" }, [
    _c(
      "div",
      { staticClass: "page_wrapper d-flex align-start flex-column bbox" },
      [
        _c(
          "div",
          { staticClass: "sticky_group white" },
          [
            _vm.switch_search === false
              ? [
                  0
                    ? _c("title-module", {
                        staticClass: "padding_x",
                        on: { handleSearch: _vm.openSearch },
                      })
                    : _vm._e(),
                  _c("navigation-bar", {
                    staticClass: "mt-20 padding_x",
                    attrs: { page_type: _vm.page_type },
                    on: { handleChangeType: _vm.changeNavigationBar },
                  }),
                ]
              : _vm._e(),
            _vm.switch_search === true
              ? _c("search-title", {
                  staticClass: "padding_x py-20",
                  on: { handleReturn: _vm.closeSearch },
                })
              : _vm._e(),
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "main_group d-flex flex-column" },
          [
            _c(
              "div",
              { staticClass: "sticky_group bbox flex-shrink-0 padding_x" },
              [
                _c("sub-navigation", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.show_nav,
                      expression: "show_nav",
                    },
                  ],
                  staticClass: "sub_navigation py-10",
                  attrs: { navigation: _vm.spm_nav },
                }),
              ],
              1
            ),
            _c("router-view"),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }