var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "views_shopping_mail_modules_title_search bbox d-flex align-center",
    },
    [
      _c("img", {
        staticClass: "icon mr-20 cp",
        attrs: {
          src: require("@/assets/images/shopping_mail/search_back.png"),
          alt: "",
        },
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.$emit("handleReturn")
          },
        },
      }),
      _c("div", { staticClass: "title" }, [_vm._v("搜索")]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }