<template>
  <div
    class="views_shopping_mail_modules_title bbox d-flex align-center justify-space-between"
  >
    <div class="title">模板商城</div>
    <div class="operation_bar d-flex align-center">
      <search-module
        class="mr-10"
        @click.stop.native="$emit('handleSearch')"
      ></search-module>
      <div class="inventory cp d-flex align-center mr-10 un_sel"
        @click.stop="goToCustomList();"
        v-if="0"
      >
        <div class="icon mr-6"></div>
        <div class="text">定制清单</div>
      </div>

      <div class="user_group cp d-flex align-center justify-center"
        :class="{is_vip: user.userVip === 1}"
      >
        <img src="~@/assets/images/shopping_mail/avatar_vip.png" alt="" class="vip"
          v-if="user.userVip === 1"
          ref="vip"
        >
        <img
          :src="`${$avatar_url}${user.thumbnail}@!small200`" alt="" class="avatar"
          @click="goToOrderList();"
        >
      </div>
    </div>
  </div>
</template>

<script>
import SearchModule from '../Common/Search.vue';
export default {
  components: {
    SearchModule,
  },
  data(){
    return {
      user: this.$tools.getCookie('user'),
    }
  },
  methods: {
  //   copy(){
  //     // // console.log(this.$refs.vip);
  //     let div = this.$refs.vip;
  //     this.setClipboard(div);
  //     this.setClipboard(div);
  //     this.setClipboard(div);

  //   },
  //   change(e){
  //     // // console.log(e);
  //     // // console.log(this.$refs.file);
  //     // // console.log(this.$refs.file.files);
  //     for(let i = 0; i<this.$refs.file.files.length; i ++){
  //       // // console.log(i, this.$refs.file.files[i])
  //       this.setClipboard(this.$refs.file.files[i]);
  //     }
  //   },
  //   async setClipboard(div) {
  //     // let data = new DataTransfer();

  //     // // data.items.add( div);
  //     // data.items.add("text/plain", 'hahaha');
  //     // navigator.clipboard.write(data).then(function() {
  //     //   /* success */
  //     //   // // console.log(navigator.clipboard);
  //     // }, function(err) {
  //     //   /* failure */
  //     //   // // console.log('false', err);
  //     // });
  //     try {
  //       const imgURL = 'https://dummyimage.com/300.png';
  //       const data = await fetch(imgURL);
  //       const blob = await data.blob();
  //       await navigator.clipboard.write([
  //         new ClipboardItem({
  //           [blob.type]: blob
  //         })
  //       ]);
  //       // // console.log('Image copied.');
  //     } catch (err) {
  //       console.error(err.name, err.message);
  //     }
  //   }
  // }
    goToOrderList(){
      //  进入我的订单前需要保存当前页面路由及数据
      this.$store.commit('set_prev_route', this.$route);
      this.$router.push({name: "shopping_mail_order_list"});
    },
    goToCustomList(){
      //  保存当前页面路由及数据
      this.$store.commit('set_prev_route', this.$route);
      this.$router.push({
        name: 'shopping_mail_custom_list'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.views_shopping_mail_modules_title{
  width  : 100%;
  // padding: 0 20px;
  .title{
    font-size  : 30px;
    font-weight: bold;
    color      : #333;
  }
  .inventory{
    @include transition;
    padding      : 0 20px;
    height       : 40px;
    color        : #fff;
    font-size    : 14px;
    border-radius: 4px;
    background: {
      image: linear-gradient(125deg, #65E1B6, #016BA2);
    }
    &:hover{
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
    }
    .icon{
      width : 16px;
      height: 18px;
      background: {
        image: url(~@/assets/images/shopping_mail/inventory.png);
      }
    }
  }
  .user_group{
    @include transition;
    position     : relative;
    width        : 44px;
    height       : 44px;
    border-radius: 4px;
    background-color: transparent;
    &.is_vip{
      background: {
        color: #F2B649;
      }
    }
    &:hover{
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
    }
    .avatar{
      width           : 40px;
      height          : 40px;
      border-radius   : 4px;
      background-color: #333;
      display         : block;
    }
    .vip{
      position: absolute;
      top     : -10px;
      right   : -10px;
    }

  }
}
</style>
