var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "views_shopping_mail_modules_title bbox d-flex align-center justify-space-between",
    },
    [
      _c("div", { staticClass: "title" }, [_vm._v("模板商城")]),
      _c(
        "div",
        { staticClass: "operation_bar d-flex align-center" },
        [
          _c("search-module", {
            staticClass: "mr-10",
            nativeOn: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$emit("handleSearch")
              },
            },
          }),
          0
            ? _c(
                "div",
                {
                  staticClass: "inventory cp d-flex align-center mr-10 un_sel",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.goToCustomList()
                    },
                  },
                },
                [
                  _c("div", { staticClass: "icon mr-6" }),
                  _c("div", { staticClass: "text" }, [_vm._v("定制清单")]),
                ]
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "user_group cp d-flex align-center justify-center",
              class: { is_vip: _vm.user.userVip === 1 },
            },
            [
              _vm.user.userVip === 1
                ? _c("img", {
                    ref: "vip",
                    staticClass: "vip",
                    attrs: {
                      src: require("@/assets/images/shopping_mail/avatar_vip.png"),
                      alt: "",
                    },
                  })
                : _vm._e(),
              _c("img", {
                staticClass: "avatar",
                attrs: {
                  src: `${_vm.$avatar_url}${_vm.user.thumbnail}@!small200`,
                  alt: "",
                },
                on: {
                  click: function ($event) {
                    return _vm.goToOrderList()
                  },
                },
              }),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }