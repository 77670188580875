var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "views_shopping_mail_modules_common_navigation_bar bbox d-flex align-center",
    },
    [
      _vm._l(_vm.list, function (item, index) {
        return [
          _c(
            "div",
            {
              key: index,
              staticClass: "unit d-flex align-center cp un_sel justify-center",
              class: {
                active: _vm.page_type === item.page_type,
                [item.page_type]: true,
              },
              on: {
                click: function ($event) {
                  return _vm.changeType(item)
                },
              },
            },
            [
              item.name === "精选"
                ? _c("div", { staticClass: "feature_icon" })
                : _vm._e(),
              _c("div", { staticClass: "name" }, [_vm._v(_vm._s(item.name))]),
              item.icon
                ? _c("img", {
                    staticClass: "icon",
                    attrs: {
                      src: require(`@/assets/images/shopping_mail/navigation_bar/${item.icon}.png`),
                      alt: "",
                    },
                  })
                : _vm._e(),
            ]
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }